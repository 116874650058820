import React from 'react';
import { t1 } from 'translate';
import Request from 'common/network/http/Request';
import nodeActions from 'actions/node/creators';
import { layouts } from 'configs/constants';
import { closeLoginDialog } from 'actions/auth/auth-dialog';
import { loginSuccess } from 'actions/auth';
import sagaActions from 'actions/saga-creators';

import '../stylesheet.scss';
import actions from 'actions/node/creators';

class LoginAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.doLoginAction = this.doLoginAction.bind(this);
  }

  doLoginAction(values, themeConfig, dispatch, history) {
    // const contentDialog = <ViettelNoti />;

    const optionsProperties = {
      modal: true,
      handleClose: true,
      autoScrollBodyContent: true,
    };

    if (values && values.lname && values.pass) {
      Request.post('/user/login', values)
        .then((response) => {
          if (response.success) {
            dispatch(loginSuccess(response.result));
            dispatch(
              sagaActions.getInformationByDomain(window.location.hostname),
            );
            dispatch(closeLoginDialog());
            if (themeConfig.type === 'sis') {
              history.push(`${window.REDIRECT_NEW_FRONTEND_THEME || ''}/`);
            }

            /*
            if (themeConfig.false) {
              dispatch(
                actions.handleOpenDialog({
                  contentDialog,
                  handleCloseDialog: () => {
                    dispatch(actions.handleOpenDialog({ openDialog: false }));
                    history.push('/dashboard');
                  },
                  optionsProperties,
                }),
              );
            }
            else {
              history.push('/dashboard');
            }
            */
            history.push(
              `${window.REDIRECT_NEW_FRONTEND_THEME || ''}/dashboard`,
            );
          } else {
            dispatch(nodeActions.snackbar(true, response.message));
          }
        })
        .catch((response) => {
          dispatch(nodeActions.snackbar(true, response.message));
        });
    } else {
      dispatch(
        nodeActions.snackbar(true, t1("the_login_fields_can't_be_empty")),
      );
    }
  }
}

export default LoginAction;
