import { call, race, select, take } from 'redux-saga/effects';
import { FINISH_EXERCISE } from 'actions/learn/exercise/normal/saga-creators';
import { getLearnItemInfoSelector } from 'common/learn';
import { isToeicTest } from 'common/learn/exercise';
import { getTimeInSeconds } from 'common/utils/Date';
import toeicFlow from './toeicFlow';
import countDownTimer from './timer';

function* waitForFinishAction(itemIid) {
  yield take(
    (action) =>
      action.type === FINISH_EXERCISE &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* countDownAndFinish(itemIid, duration) {
  yield call(countDownTimer, duration, itemIid);
}

export default function* coreFlow(itemIid) {
  const getInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(getInfo, itemIid);

  if (isToeicTest(info)) {
    let racingFlows = {
      waitForFinishAction: call(waitForFinishAction, itemIid),
      toeic: call(toeicFlow, itemIid),
    };
    const duration = getTimeInSeconds(info.timeRemaining || info.duration);
    if (duration) {
      racingFlows = {
        ...racingFlows,
        countDownAndFinish: call(countDownAndFinish, itemIid, duration),
      };
    }
    yield race(racingFlows);
  } else {
    let racingFlows = {
      waitForFinishAction: call(waitForFinishAction, itemIid),
    };
    const duration = getTimeInSeconds(info.timeRemaining || info.duration);
    if (duration) {
      racingFlows = {
        ...racingFlows,
        countDownAndFinish: call(countDownAndFinish, itemIid, duration),
      };
    }
    yield race(racingFlows);
  }
}
