import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';
import lodashGet from 'lodash.get';

const Event = Loadable({
  loader: () => import('components/front-end/dashboard/ums/event'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: (props) => {
    const iid = lodashGet(props, 'match.params.iid');
    return <Event iid={iid} />;
  },
};
