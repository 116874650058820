// register the persisting reducers (we will store to the localStorage)
export const whitelist = [
  'user',
  'translations',
  'maskedTeacher',
  'collapsedMenu',
  //'form.node_new.values',
];

export const blacklist = [];
