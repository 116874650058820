/**
 * Created by Peter Hoang Nguyen on 3/17/2017.
 */

import { WINDOW_RESIZE } from 'layouts/learning/actions';

import { ON_SHOW_QUICK_VIEW } from 'layouts/learning/quickview/actions';
import { commonActionTypes } from 'actions/common';
import { CONFIG_MENU_LEFT } from '../../actions/creators';

const commonState = {
  screenSize: {},
  bodyScreenSize: {},
  showQuickView: false,
  showFormView: { default: false },
};

const CommonState = (state = commonState, action) => {
  let newState = {};
  switch (action.type) {
    case WINDOW_RESIZE:
      newState = {
        ...state,
        screenSize: action.screenSize,
        bodyScreenSize: action.bodyScreenSize,
      };
      break;
    case ON_SHOW_QUICK_VIEW:
      newState = {
        ...state,
        showQuickView: action.showQuickView,
      };
      break;
    case CONFIG_MENU_LEFT: {
      newState = {
        ...state,
        leftMenuConfig: action.configs,
      };
      break;
    }
    case commonActionTypes.SET_STATUS_FORM_VIEW:
      const { params } = action;
      const viewId = params.viewId || 'default';
      const oldData = state.showFormView[viewId] || {};
      newState = {
        ...state,
        showFormView: {
          ...state.showFormView,
          [viewId]: {
            ...oldData,
            ...params,
          },
        },
      };
      break;
    default:
      return state;
  }
  return newState;
};
export default CommonState;
