import Loadable from 'components/common/async-loader/Loadable';
import { DefinedUrlParams } from '../common';

const LearningIndex = Loadable({
  loader: () => import('../../components/learn'),
});

export const courseOverViewForUser = {
  componentId: 'CourseOverviewForUser',
  path: `/:${DefinedUrlParams.COURSE_VIEW_TYPE}/:${
    DefinedUrlParams.COURSE_IID
  }(\\d+)-:${DefinedUrlParams.SYLLABUS_IID}(\\d+)/:${
    DefinedUrlParams.USER_IID
  }(\\d+)/:slug.html`,
  component: LearningIndex,
  exact: true,
};

export const learnElement = {
  componentId: 'LearnElement',
  path: `/:${DefinedUrlParams.COURSE_VIEW_TYPE}/:${
    DefinedUrlParams.PATH_IID
  }(\\d+)/:${DefinedUrlParams.COURSE_IID}(\\d+)-:${
    DefinedUrlParams.SYLLABUS_IID
  }(\\d+)/:${DefinedUrlParams.LEARN_ELEMENT_PATH}/:slug.html`,
  component: LearningIndex,
  exact: true,
};

export const learnElementWithSubChild = {
  componentId: 'LearnElementWithSubChild',
  path: `/:${DefinedUrlParams.COURSE_VIEW_TYPE}/:${
    DefinedUrlParams.PATH_IID
  }(\\d+)/:${DefinedUrlParams.COURSE_IID}(\\d+)-:${
    DefinedUrlParams.SYLLABUS_IID
  }(\\d+)/:${DefinedUrlParams.LEARN_SUB_ELEMENT_IID}/:${
    DefinedUrlParams.LEARN_ELEMENT_PATH
  }/:slug.html`,
  component: LearningIndex,
  exact: true,
};

export const courseOverview = {
  componentId: 'CourseOverview',
  path: `/:${DefinedUrlParams.COURSE_VIEW_TYPE}/:${
    DefinedUrlParams.COURSE_IID
  }(\\d+)-:${DefinedUrlParams.SYLLABUS_IID}(\\d+)/:slug.html`,
  component: LearningIndex,
  exact: true,
};

export const previewCourseLearn = {
  componentId: 'PreviewCourseLearn',
  path: `/:${DefinedUrlParams.COURSE_VIEW_TYPE}/:${
    DefinedUrlParams.COURSE_IID
  }(\\d+)-:${DefinedUrlParams.SYLLABUS_IID}(\\d+)/:${
    DefinedUrlParams.LEARN_ELEMENT_PATH
  }/:slug.html`,
  component: LearningIndex,
  exact: true,
};

export const courseOverviewByPath = {
  componentId: 'CourseOverviewByPath',
  path: `/:${DefinedUrlParams.COURSE_VIEW_TYPE}/:${
    DefinedUrlParams.PATH_IID
  }(\\d+)/:${DefinedUrlParams.COURSE_IID}(\\d+)-:${
    DefinedUrlParams.SYLLABUS_IID
  }(\\d+)/:slug.html`,
  component: LearningIndex,
  exact: true,
};
