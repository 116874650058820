/**
 * Created by Peter Hoang Nguyen on 4/13/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { loginOAuthSuccessRequest } from 'actions/auth/saga-creators';
import { layouts } from 'configs/constants';
import { getThemeConfig } from 'utils/selectors';

class SocialAuth extends React.Component {
  style = { paddingLeft: 0 };

  constructor(props) {
    super(props);
    this.state = {};
  }

  responseGoogle = (response) => {
    const { dispatch } = this.props;
    dispatch(loginOAuthSuccessRequest('google', response.tokenId));
  };

  responseFacebook = (response) => {
    const { dispatch } = this.props;
    dispatch(loginOAuthSuccessRequest('facebook', response.accessToken));
  };

  render() {
    const {
      googleClientId,
      facebookAppId,
      themeConfig,
      enableRegisters,
    } = this.props;
    if (!enableRegisters) return '';
    let numberOfLoginOptions = 0;
    if (enableRegisters.includes('register_facebook') && facebookAppId)
      numberOfLoginOptions += 1;
    if (enableRegisters.includes('register_google') && googleClientId)
      numberOfLoginOptions += 1;
    if (numberOfLoginOptions === 0) return '';

    const wrapperProperties =
      numberOfLoginOptions === 2
        ? {
            className: 'col-xs-6',
            style: { padding: '0 15px 0 0' },
          }
        : {
            className: 'col-xs-12',
            style: { padding: '0' },
          };

    if (1) {
      return (
        <div className="center-block tools row" style={this.style}>
          {facebookAppId &&
            enableRegisters &&
            enableRegisters.includes('register_facebook') && (
              <div {...wrapperProperties}>
                <FacebookLogin
                  appId={facebookAppId}
                  fields="name,email,picture"
                  textButton=" Facebook"
                  callback={this.responseFacebook}
                  cssClass="my-facebook-button-class"
                  icon="fa-facebook"
                />
              </div>
            )}
          {googleClientId &&
            enableRegisters &&
            enableRegisters.includes('register_google') && (
              <div {...wrapperProperties}>
                <GoogleLogin
                  clientId={googleClientId}
                  buttonText={
                    <div>
                      <i className="fa fa-google-plus" aria-hidden="true" />{' '}
                      Google
                    </div>
                  }
                  className="another-login-icon googleplus"
                  onSuccess={this.responseGoogle}
                />
              </div>
            )}
        </div>
      );
    }

    return (
      <div className="center-block tools">
        {facebookAppId &&
          enableRegisters &&
          enableRegisters.includes('register_facebook') && (
            <FacebookLogin
              appId={facebookAppId}
              fields="name,email,picture"
              textButton=""
              callback={this.responseFacebook}
              cssClass="my-facebook-button-class"
              icon="fa-facebook"
            />
          )}
        {googleClientId &&
          enableRegisters &&
          enableRegisters.includes('register_google') && (
            <GoogleLogin
              clientId={googleClientId}
              buttonText={
                <i className="fa fa-google-plus" aria-hidden="true" />
              }
              className="another-login-icon googleplus"
              onSuccess={this.responseGoogle}
            />
          )}
      </div>
    );
  }
}

SocialAuth.childContextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};

SocialAuth.propTypes = {
  facebookAppId: PropTypes.string,
  googleClientId: PropTypes.string,
};

SocialAuth.defaultProps = {
  facebookAppId: '',
  googleClientId: '',
};

const mapStateToProps = (state, props) => {
  const conf = state.domainInfo.conf;
  const { themeConfig } = props;
  const appName = (themeConfig && themeConfig.layout) || 'pixelz';

  const facebookAppIdConfig = conf && conf['sso:facebook:app_id'];
  const facebookConfigByAppName =
    conf && conf['sso:facebook:config_by_app_name'];
  let facebookAppId =
    (facebookConfigByAppName &&
      facebookConfigByAppName[appName] &&
      facebookConfigByAppName[appName].app_id) ||
    facebookAppIdConfig;
  if (facebookAppId === 'none') {
    facebookAppId = null;
  }

  let googleClientId = conf && conf['sso:google:client_id'];
  if (googleClientId === 'none') {
    googleClientId = null;
  }

  return {
    googleClientId,
    facebookAppId,
    enableRegisters: conf && conf.enable_registers,
    themeConfig: getThemeConfig(state),
  };
};

export default connect(mapStateToProps)(SocialAuth);
