import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const Timetable = Loadable({
  loader: () => import('components/timetable/views'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: (props) => (
    <Timetable {...props} rootRouteId="site" />
  ),
};
