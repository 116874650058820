import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const TeacherSchedule = Loadable({
  loader: () => import('components/timetable_v2/viewers/ScheduleOverview'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: TeacherSchedule,
};
