import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../../Naming';
import getUser from '../../../../common/auth';

const ChangePassword = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/user/profile/ChangePassword'),
  loading: Loading,
});

const user = getUser();

export default {
  [DEFINED_PAGE_CODES.defaultPage]: (props) => (
    <ChangePassword {...props} user={user} blogRouterId="site" />
  ),
};
