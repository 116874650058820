import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const ProgressReportsLayout = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/reports/student-detail'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: (props) => (
    <ProgressReportsLayout {...props} routeId={'/site'} />
  ),
};
