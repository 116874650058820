// 'site/ai/get-data-schema'
import Perm from 'common/utils/Perm';
import getUser from 'common/auth';
import Store from 'store';
import { logout } from 'actions/auth';
export default {
  abstract_role:
    'abac-role/api/get-abstract-roles-options-for-specific-role-type',
  synchronize_progress:
    '/progress/data/synchronize-progress-from-database-to-redis',
  synchronize_unlock: '/path/data/unlock',
  get_question_types: '/question/api/get-question-types',
  generate_syllabus_from_template: '/syllabus/new-syllabus-from-template',
  generate_exam_scos_from_template:
    '/syllabus/generate-exam-scos-from-template',
  cache_tco_info: '/site/data/batch-update-tco-info-to-redis',
  cache_users_info: '/user/data/cache-all-users-to-redis',
  get_courses_to_register: '/course/api/get-courses-allowed-to-register',
  // register_the_course: '/register-the-course',
  register_the_course: '/course/register-the-course',
  //
  withdraw_the_course: '/course/withdraw-the-course',
  fee_search: '/fee/search',
  fee_group_by_users: '/fee/api/get-fees-group-by-users',
  syllabus_search: '/syllabus/my',
  syllabus_update: '/syllabus/update',
  bank_search: '/api/v1/syllabus/search',
  get_categories_of_questions_in_exam_template:
    '/exam-template/get-categories-of-questions-in-exam-template',
  get_levels_of_questions_in_exam_template:
    '/exam-template/get-levels-of-questions-in-exam-template',
  course_search: '/course/my',
  course_search_for_invite: '/course/search?for_invite=1',
  course_stationery_usage_rate:
    '/asset/api/search-stationery-usage-rate?type=course',
  blog_search: '/api/v2/category/search?type=blog',
  role_search: 'role/search',
  user_group_report: '/report/search-report',
  certificate_users: '/course/certificate/get-certificate-users',
  get_graduation_users: '/course/api/get-users-with-score-information',
  get_progress_of_graduating_seniors:
    '/course/api/get-progress-of-graduating-seniors',
  get_progress_of_offline_exam: '/course/api/get-progress-of-offline-exam',
  set_rubric_progress: '/skill/api/save-rubric-score',
  set_user_pass_fail_status_in_course:
    '/course/api/set-user-pass-fail-status-in-course',
  save_offline_exam_academic_score:
    '/skill/api/save-offline-exam-academic-score',
  get_sessions_for_user: '/session/api/get-sessions-for-user',
  course_marking: '/course/marking',
  course_communicate: '/course/communicate',
  course_set_price_item: ({ id, tcoIid }) =>
    `course/update?id=${id}&_sand_step=tco_price&tco_iid=${tcoIid}&submit=1`,
  get_snippet: '/api/v1/syllabus/get',
  get_course_api: '/course/api/get-course',
  get_skill_info: '/skill/api/get',
  get_session_api: '/session/api/get-detail',
  get_registered_course_detail: '/course/api/get-registered-course-detail',
  get_current_semesters: '/semester/get-current-semesters',
  get_work_note: 'work-note/search',
  get_session_info: '/session/detail',
  get_data_overview: '/user/api/get-student-overview-stats',
  get_data_criteria_budgetary:
    '/budgetary-allocations/api/get-data-criteria-budgetary',
  get_session_will_assigned_for_user:
    '/session/get-session-will-assigned-for-user',
  calculate_attendant_users: '/attendance/calculate-attendant-users',
  calculate_attendances_score: '/attendance/calculate-attendances-score',
  session_search_user: '/session/api/search-users',
  get_page_by_category_api: '/page/api/get-pages-by-category',
  conf_reload_default: '/conf/load-default',
  conf_search: 'conf/api/search',
  venue_search: '/venue/search',
  venue_room_search: '/venue/search',
  get_venue_by_parent: '/venue/api/get-venue-by-parent',
  get_all_venue: '/venue/api/get-all-venue',
  asset_search: '/asset/index/search',
  report_stationery_search: '/asset/api/search-report-stationery',
  report_equipment_search: '/asset/api/search-report-equipment',
  report_future_projection_search: '/asset/api/search-report-future-projection',
  stationery_asset_search: '/asset/index/search-stationery',
  stationery_search_import_items: '/asset/api/search-stationery-import-items',
  add_stationery_items: '/asset/api/add-stationeries',
  update_stationery_items: '/asset/index/update?type=stationery',
  change_stationery_quantity: '/asset/api/change-stationery-quantity',
  timetable_search: '/timetable/search',
  sinvite_search: '/sinvite/api/search',
  skill_search: '/skill/search',
  skill_relation_search: '/skill/search',
  skill_edit_full_info: '/skill/api/get-full-skill-info',
  page_search: '/page/search',
  user_search: '/user/search',
  staff_search: '/user/search?_sand_step=staff',
  parent_search: '/user/search?_sand_step=parent',
  support_payment_search: '/user/search',
  invoice_search: '/invoice/search',
  deposit_search: '/invoice/search',
  search_teacher_by_visible: '/user/api/search-teacher-by-visible',
  user_school_search: '/user/search',
  students_to_add_scholarship_search: '/user/search',
  transaction_search: '/transaction/search',
  import_students_request: '/user/data/new',
  import_budgetary: '/import/index/import-budget',
  import_students_request_search: '/user/data/search',
  import_request_search: '/import/index/search',
  import_questions_request_confirm: '/question/import/import-data',
  import_students_to_group_in_university:
    '/user/data/import-students-to-group-in-university',
  import_students_download_template_request: '/user/template/download-template',
  import_students_action_request: '/user/data/import-data',
  import_rubrics_request: 'take/data/new',
  import_rubrics_request_search: '/take/data/search',
  import_rubrics_action_request: '/take/data/import-data',
  new_organizations_import: '/organization/api/new-organizations-import',
  organizations_import_search: '/organization/api/import-search',
  import_organizations: '/organization/api/import-organizations',
  new_path_import: '/path/data/new-path-import',
  path_import_search: '/path/data/path-import-search',
  import_path: '/path/data/import-path',
  download_certificate: '/course/certificate/create-certificate',
  send_email_with_certificate:
    '/course/certificate/send-email-with-certificate',
  send_email_with_certificates: '/course/certificate/send-certificate-users',
  customize_test: '/paper/customize-test',
  room_search: '/room/search',
  contract_search: '/contract/search',
  ico_search: '/ico/search',
  semester_search: '/semester/search',
  teaching_experience_search: '/user/search-teaching-experiences',
  path_search: '/path/search',
  program_search: '/path/search',
  subjectgroup_search: '/path/search',
  classgroup_search: '/path/search',
  teaching_plan_overview: '/plan/overview',
  plan_search: '/plan/search',
  credit_plan_search: '/plan/search-credit',
  major_program_search: '/plan/search-major-program',
  multi_degree_search: '/degree/api/search-multi-degree',
  open_paths: '/path/api/get-open-paths',
  get_categories_info: '/api/v2/category/get-categories-info',
  get_category_info: '/api/v2/category/get-category-info',
  academic_category_search: '/api/v2/category/search?type=academic',
  asset_category_search: '/api/v2/category/search?type=asset',
  job_position_search: '/job-position/api/search?type=job_position',
  job_position_delete: '/job-position/api/delete',
  get_job_position_options: '/job-position/api/get-job-position-options',
  get_job_positions_for_input_auto_complete:
    '/job-position/api/get-job-positions-for-input-auto-complete',
  fee_category_search: '/api/v2/category/search?type=fee',
  major_search: '/api/v2/category/search?type=major',
  category_delete: '/category/index/delete',
  stationery_item_delete: '/asset/index/delete-stationery',
  user_in_group_search_by_skill: 'api/v2/user/search-by-skill',
  template_search: '/template/search',
  fetch_node: '/api/v2/syllabus/get',
  get_total_notifications: 'notification/api/get-total-notifications',
  get_teachers_who_can_teach_syllabus: '/api/v2/contract/teachers',
  roles_menu_url: '/school/api/get-menu-config-by-role',
  children_category: '/category/api/get-children-categories',
  faq_search: '/faq/search',
  degree_search: '/degree/search',
  degree_preview: '/degree/preview',
  event_search: '/event/search',
  get_events_for_select_field_and_multi_checkbox_options:
    '/event/api/get-events-for-select-field-and-multi-checkbox-options',
  get_event_occurrences_of_users: '/event/api/get-event-occurrences-of-users',
  get_events_of_users: '/event/api/get-events-of-users',
  get_upcoming_event_blogs: '/page/search?is_upcoming=true',
  sessions_search: '/session/api/get-sessions-in-time-interval',
  get_session_in_current_time: '/session/api/get-session-in-current-time',
  join_class_by_session: '/session/api/join-class',
  get_school_dashboard_overview_info:
    '/site/api/get-school-dashboard-overview-info',
  update_max_iid_to_redis: '/site/data/update-iid',
  get_current_classes: 'course/api/get-current-classes-info',
  get_current_rooms: 'venue/api/get-current-rooms-info',
  get_current_users: 'user/api/get-current-users-info',
  wallet_search: '/wallet/api/get-wallets',
  wallet_type_search: '/wallet-type/search',
  passdef_search: '/passdef/search',
  dashboard_configs: (mode, itemsPerPage) => {
    if (mode === 'compulsoryCourses') {
      return '/site/api/get-invited-courses?type=compulsory';
    } else if (mode === 'assignedCourses') {
      return '/site/api/get-invited-courses?type=waiting';
    } else if (mode === 'coursesInProgress') {
      return `site/api/my-courses?type=learning&items_per_page=${itemsPerPage ||
        -1}`;
    } else if (mode === 'completedCourses') {
      return `site/api/my-courses?type=learning&mode=passed&items_per_page=${itemsPerPage ||
        -1}`;
    } else if (mode === 'failedCourses') {
      return `site/api/my-courses?type=learning&mode=failed&items_per_page=${itemsPerPage ||
        -1}`;
    } else if (mode === 'publicCourses') {
      const api = 'site/api/get-public-courses';
      return typeof itemsPerPage !== 'undefined'
        ? `${api}?items_per_page=${itemsPerPage}`
        : api;
    } else if (mode === 'featuredCourses') {
      return 'site/api/get-featured-courses';
    } else if (mode === 'rejectedCourses') {
      return '/site/api/get-invited-courses?type=reject';
    } else if (mode === 'myPaths') {
      return '/site/api/my-path';
    } else if (mode === 'mySkills') {
      return '/student/api/get-positions-of-user-with-skills';
    } else if (mode === 'myEnrolmentPlans') {
      return '/student/api/get-enrolment-plans';
    } else if (mode === 'programsOfSkill') {
      return '/syllabus/api/get-credit-syllabuses-of-skill';
    } else if (mode === 'categoriesCourses') {
      return 'site/api/get-public-courses';
    }
    return '/';
  },
  menu_configs: (mode) => {
    let isPerm = Perm.hasPerm('root');
    let user = getUser();
    if (mode === 'admin') {
      if (user.info.roles.length > 0) {
        return '/api/v2/site/teacher-roles-menu';
      } else {
        // Store.dispatch(logout('/site'));
        window.location.assign('/');
      }
    }
    return '/';
  },
  post_new_node: (ntype, action = 'new') => {
    if (ntype === 'new-package') {
      return '/card/new-package';
    }
    if (ntype === 'relation') {
      return 'api/v1/site/add-relation';
    }
    if (['major', 'academic-category', 'fee-category'].includes(ntype)) {
      return '/category/index/new';
    }
    if (['organization', 'job-position'].includes(ntype)) {
      return `/${ntype}/api/${action}`;
    }

    return `/${ntype}/${action}`;
  },
  update_node: (ntype, step) => {
    if (ntype === 'translate')
      return `/${ntype}/index/update${step ? `?step=${step}` : ''}`;
    if (ntype === 'new-package') {
      return `card/update-package${step ? `?step=${step}` : ''}`;
    }
    if (ntype === 'enrolment_plan') {
      return `enrolment-plan/update${step ? `?_sand_step=${step}` : ''}`;
    }
    return `/${ntype}/update${step ? `?_sand_step=${step}` : ''}`;
  },
  delete_node: (ntype) => {
    if (ntype === 'translate') return `/${ntype}/index/delete`;
    if (ntype === 'user_goal') return '/user-goal/delete';
    if (ntype === 'abac_role') return '/abac-role/delete';
    if (ntype === 'enrolment_plan') return '/enrolment-plan/delete';
    if (ntype === 'training_plan') return '/training-plan/delete';
    if (ntype === 'time_sheet') return '/time-sheet/delete';
    return `/${ntype}/delete`;
  },
  get_detail: (item, ntype) => {
    const module = ntype || item.ntype;
    if (!module) {
      return '/';
    }
    if (item) {
      return `/${module}/detail?iid=${item.iid}`;
    }
    return `/${module}/detail`;
  },
  exam_shift_search: '/course/my',
  offline_exam_search: '/course/my',
  get_info_of_class_groups: '/user/api/get-info-of-class-groups',

  user_learn_search: '/api/v2/report/user-learn',
  teacher_overview_report: '/api/v2/report/teacher-overview',
  xpeak_vip_search: '/api/v2/report/xpeak-vip',
  teaching_hours_search: '/api/v2/report/teaching-hours',
  teachers_by_faculty_search: '/api/v2/report/teaching-hours-group-by-teacher',
  teachers_by_credit_search: '/api/v2/report/teaching-hours-group-by-teacher',
  rooms_capacity_search: '/api/v2/report/rooms-capacity',
  scores_by_course_search: '/api/v2/report/scores-by-course',
  scores_by_semester_credit_search: '/api/v2/report/scores-by-semester-credit',
  overall_report_courses_of_organization:
    '/api/v2/report/overall-report-courses-of-organization',
  overall_report_of_organization:
    '/api/v2/report/overall-report-of-organization',
  compare_organizations: '/api/v2/report/compare-organizations',
  get_report_progress_master: '/api/v2/report/get-report-progress-master',
  get_report_by_subject: '/api/v2/report/get-report-by-subject',
  get_export_by_subject: '/api/v2/report/get-export-by-subject',
  get_student_detail_progress: '/api/v2/report/get-student-detail-progress',
  get_organization_progress: '/api/v2/report/get-organization-progress',
  progress_of_path: '/api/v2/report/progress-of-path',
  progress_of_path_user: '/api/v2/report/progress-of-path-user',
  enrolment_plan_credit_progress:
    '/api/v2/report/get-enrolment-plan-credit-progress',
  enrolment_plan_stationery_usage_rate:
    '/asset/api/search-stationery-usage-rate?type=enrolment-plan',
  enrolment_plan_overall_progress:
    '/api/v2/report/get-enrolment-plan-overall-progress',
  enrolment_plan_overall_learning_statistics:
    '/api/v2/report/enrolment-plan-overall-learning-statistics',
  enrolment_plan_learners_progress:
    '/api/v2/report/get-enrolment-plan-learners-progress',
  enrolment_plan_not_started_learners:
    '/api/v2/report/get-enrolment-plan-not-started-learners',
  organizations_learning_reports:
    '/api/v2/report/get-organizations-learning-reports',
  enterprise_dashboard_reports:
    '/api/v2/report/get-enterprise-dashboard-reports',

  change_exam_shift_search: '/user/search',
  province_district_search: '/pds/api/search-province',
  school_search: '/pds/api/search-school',
  paper_search: '/paper/index/search',
  translate_search: '/translate/index/search',
  redis_search: '/redis/index/search',
  // report_number_of_users_by_province:
  //   '/report/preview-report-number-of-users-by-province',
  // report_number_of_users_by_district:
  //   '/report/preview-report-number-of-users-by-district',
  // report_number_of_users_by_school_in_province:
  //   '/report/preview-report-number-of-users-by-school-in-province',
  // report_number_of_users_by_school_in_district:
  //   '/report/preview-report-number-of-users-by-school-in-district',
  // report_number_of_users_in_one_round_by_school:
  //   '/report/preview-report-number-of-users-in-one-round-by-school',
  // report_chart_users_by_province:
  //   '/report/preview-chart-number-of-users-by-province',
  // report_chart_users_by_district:
  //   '/report/preview-chart-number-of-users-by-district',
  // report_chart_users_by_school_in_province:
  //   '/report/preview-chart-number-of-users-by-school-in-province',
  // report_chart_users_by_school_in_district:
  //   '/report/preview-chart-number-of-users-by-school-in-district',
  // report_chart_users_by_grade: '/report/preview-chart-number-of-users-by-grade',
  export_user: '/user/data/export',
  export_abnormal_accounts: '/user/api/export-abnormal-accounts',
  export_graduating_seniors: '/group/api/export-graduating-seniors',
  export_members_in_senior_group: '/group/api/export-members-in-senior-group',
  export_users_in_course: '/course/data/export-users-in-course',
  export_syllabus_for_cost: '/course/data/export-syllabus-for-cost',
  export_budgetary: '/budgetary-allocations/data/export',
  export_users_in_offline_exam: '/course/data/export-users-in-offline-exam',
  export_students_by_course: '/report/data/export-students-by-course',
  export_course_by_plan: '/report/data/export-course-by-plan',
  export_teaching_hours: '/report/data/export-teaching-hours',
  export_teachers_by_faculty:
    '/report/data/export-teaching-hours-group-by-teacher?mode=faculty',
  export_teachers_by_credit:
    '/report/data/export-teaching-hours-group-by-teacher?mode=credit',
  export_rooms_capacity: '/report/data/export-rooms-capacity',
  export_scores_by_course: '/report/data/export-scores-by-course',
  export_scores_by_semester_credit:
    '/report/data/export-scores-by-semester-credit',
  cron_jobs_to_remind_finish_course:
    '/notification/api/remind-finish-course-for-all-users',
  // cron_jobs_to_import_organizations: '/category/api/import-organizations',
  cron_jobs_to_log_report_courses: '/report/api/log-report-courses',
  cron_jobs_to_log_report_enrolment_plans:
    '/report/api/log-report-enrolment-plans',
  cron_jobs_to_log_report_enrolment_plan_learner_progress:
    '/enrolment-plan/report/sync-learner-progress-from-redis-to-db',
  cron_jobs_to_log_report_training_plan_learner_progress:
    '/training-plan/report/sync-learner-progress-from-redis-to-db',
  cron_jobs_to_log_report_organizations: '/report/api/log-report-organizations',

  cron_jobs_to_log_courses_for_student_was_invited_in_enrollment_plan_to_report_progress_master:
    '/report/api/log-courses-for-student-was-invited-in-enrollment-plan-to-report-progress-master',
  cron_jobs_to_log_feedback_to_report_progress_master:
    '/report/api/log-feedback-to-report-progress-master',
  cron_job_to_log_expense_to_report_budgetary_master:
    '/budgetary-allocations/api/log-expense-to-report-budgetary',
  cron_job_to_log_expense_to_report_budgetary_master_by_equivalent_positions:
    '/budgetary-allocations/api/log-expense-to-report-budgetary-equivalent-positions',
  cron_jobs_to_log_teaching_hours: '/report/api/log-teaching-hours',
  cron_jobs_to_log_courses_number: '/report/api/log-courses-number',
  cron_jobs_to_log_feedback_info: '/report/api/log-feedback-info',
  cron_jobs_to_log_progress_of_course: 'report/api/log-report-progress-courses',
  cron_jobs_to_log_progress_of_semester_credit:
    'report/api/log-report-progress-semester-credits',
  cron_jobs_to_log_progress_of_syllabus:
    'report/api/log-report-progress-syllabus',
  cron_jobs_to_log_rooms_capacity: '/report/api/log-rooms-capacity',
  add_relation: 'api/v1/site/add-relation',
  remove_relation: 'api/v1/site/remove-relation',
  // adblock browser extensions would stop this request
  // tracker_progress: (action = 'get') => `/tracker?a=${action}`,
  tracker_progress: (action = 'get') => `/trckr?a=${action}`,
  featured_news_blogs: '/page/api/get-news-blogs',
  get_page: '/page/api/get-page',
  most_read_featured_news_blogs: '/page/api/get-news-blogs',
  newest_featured_news_blogs: '/page/api/get-news-blogs',
  newest_featured_news_blogs_ggg: '/page/api/get-news-blogs?items_per_page=4',
  get_tcos_price: '/course/api/get-tcos-price',
  pay_for_path: '/path/api/pay',
  get_paths: '/path/api/get-paths?&_v=101',
  get_package_locked_paper: '/paper/api/get-package-payment',
  get_trial_packages: '/site/api/get-trial-packages?&_v=2',
  add_money_by_card: '/user/bk-thecao',
  login_oauth_success: '/user/oauth-success',
  forgot_password: '/user/forgot-password',
  get_bank_accounts: '/site/payment/bank-accounts',
  get_user_balance: '/user/balance/?&_v=2',
  get_information_by_domain: '/site/get-information-by-domain',
  get_translations: '/site/api/get-translations',
  get_phonetic_diff: '/vocabset/api/diff-match-phonetic',
  user_info: '/user/api/full-info',
  get_in_course_exam_info: '/take/api/get-in-course-exam-info',
  get_info_on_the_tests: '/course/api/info-marking-on-the-tests',
  subscribe_newsletter: 'subscribe-newsletter',
  handle_invite_course: '/user/index/user-perform-invite-item',
  perform_action_on_relations_between_user_and_learning_item:
    '/sinvite/api/perform-action-on-relations-between-user-and-learning-item',
  search_home_courses: '/course/api/search-courses',
  get_chat_info: '/api/v2/chat/get',
  card_list: 'card/list',
  search_card: 'card/search-card',
  cancel_package: 'card/cancel-package',
  cancel_card: 'card/cancel-card',
  generate_card: 'card/generate-card',
  package_detail: 'card/package-detail',
  system_school_search: 'school/index/search',
  new_invite: '/invite/api/new',
  remove_invite: '/api/v2/invite/remove',
  getTakeDetail: '/take/api/get-question-answer',
  suggest_autocomplete: '/suggest',
  get_information_report: '/report/api/get-information-learn-item',
  get_takes_by_course: 'course/api/my-takes-by-course',
  get_adaptive_skills: '/skill/api/get-tree-data',
  deep_clone: '/site/index/deep-clone',
  get_syllabus_with_assignment_of_course:
    '/api/v2/syllabus/get-syllabus-with-assignment-data-of-course',
  get_current_courses: '/student/api/get-current-courses',
  get_assigned_courses: '/student/api/get-assigned-courses',
  update_translate: '/translate/index/update',
  get_open_ended_answers_for_assignment_group:
    '/take/api/get-open-ended-answers-for-assignment-group',
  reset_progress: '/progress/api/reset-progress',
  get_fees_of_user: '/fee/api/get-fees-of-user',
  get_all_assignments: '/syllabus/api/get-assignment-of-user',
  get_personal_assignments: '/syllabus/api/get-personal-assignment-of-user',
  check_validity: '/syllabus/check-validity',
  session_search: '/session/search',
  check_take_plagiarism: '/take/api/check-plagiarism',
  set_scale_for_skills: '/skill/api/set-scale-for-skills',
  add_room_to_class: '/course/api/add-rooms',
  remove_room_from_class: '/course/api/remove-room',
  attendance_manage: (courseIid, sessionIid, rootUrl) => {
    if (rootUrl === 'admin') {
      return `/${rootUrl}/course/${courseIid}/session/${sessionIid}/attendance`;
    }
    return `/${rootUrl ||
      'learn'}/timetable/${courseIid}-${sessionIid}/attendance`;
  },
  get_category_tree_related_to_user_from_arbitrary_node:
    '/category/api/get-category-tree-related-to-user-from-arbitrary-node',
  get_transcript: '/student/api/get-transcript',
  get_subject_score_of_user: '/user/api/get-subject-score-of-user',
  get_transcript_by_program_tree: '/student/api/get-transcript-by-program-tree',
  get_import_organization_file_template:
    '/organization/api/get-import-organization-file-template',
  get_import_path_file_template: '/path/data/get-import-path-file-template',
  bank_import_ntype: () => 'import/index/import',
  get_import_file_template_for_ntype: (ntype, categoryType) => {
    if (categoryType) {
      return `user/template/get-import-template?category_type=${categoryType}`;
    }
    return `${ntype}/template/get-import-template`;
  },
  search_academic_categories_that_user_can_create_content:
    '/category/academic/search-academic-categories-that-user-can-create-content',
  goal_search: '/goal/search',
  user_goal_search: '/user-goal/search',
  get_data_for_user_goal_progress_chart:
    '/user-goal/api/get-data-for-user-goal-progress-chart',
  get_skills_of_user_goals: 'user-goal/api/get-skills-related-to-user-goal',
  search_job_position_that_user_can_use_to_create_user_goal:
    '/job-position/goal/search-job-position-that-user-can-use-to-create-user-goal',
  search_custom_goals_to_assign_to_user:
    'goal/api/search-custom-goals-to-assign-to-user',
  finance_template_search: 'finance-template/search',
  benefit_search: 'finance-template/search',
  get_benefits:
    'finance-template/search?classification[]=benefit&status[]=approved',
  applied_fee_template_created: 'applied-fee-template/search',
  fee_collecting_phase_search: 'fcp/search',
  get_all_target_groups: '/target-group/api/get-all-target-groups',
  user_major_search: '/user-major/search',
  send_sms_for_target_group: '/user-major/api/send-sms-for-target-group',
  export_user_major: '/user-major/api/export',
  processing_requests_search: '/request/search',
  processing_req_search: '/req/search',
  get_duplicated_credit_syllabus_in_program:
    '/path/api/get-duplicated-credit-syllabus-in-program',
  get_all_assignments_that_user_can_mark_now:
    '/syllabus/api/get-all-assignments-that-user-can-mark-now',
  get_user_group_categories: '/api/v2/category/search',
  get_finance_template_possible_target_payers:
    '/finance-template/api/get-possible-target-payers',
  feedback_search: '/feedback/get',
  search_dir_content: 'file/search?_sand_step=dir_content',
  get_file_root_dirs: 'file/api/get-root-dirs',
  get_all_file_ancestors: 'file/api/get-all-file-ancestors',
  upload_file: 'file/upload',
  upload_media: `${window.APP_MEDIA_SERVER_URL}/media/upload`,
  upload_avatar: 'file/upload',
  send_comment: '/feedback/new',
  change_notifications_status: '/notification/api/change-notifications-status',
  get_collaborating_item_comments:
    '/feedback/get-comments-for-collaborating-item',
  count_collaborating_item_comments:
    '/feedback/count-comments-for-collaborating-item',
  fetch_comments_by_syllabuses_iid: '/feedback/get-comments-by-syllabuses-iid',
  get_all_comment_types: '/feedback/get-all-comment-types',
  resolve_comment: '/feedback/update',
  delete_files: 'file/api/delete',
  get_abac_role: 'abac-role/api/get',
  comment_search_by_global_survey:
    '/survey/api/search-comments-by-global-survey',
  search_open_ended_answers: '/survey/api/search-open-ended-answers',
  get_permission_modules_to_create_role:
    '/abac-role/api/get-permission-modules-to-create-role',
  save_violation: '/take/api/save-violation',
  get_role_options: '/abac-role/api/get-role-options',
  get_roles_of_user_in_applied_target:
    '/user-abac-role/api/get-roles-of-user-in-applied-target',
  get_roles_of_user: '/user-abac-role/api/get-roles-of-user',
  abac_role_assign_multi_user_as_roles:
    '/user-abac-role/assign-multi-user-as-roles',
  set_abac_role_for_user_from_abstract_code:
    '/user-abac-role/api/set-abac-role-for-user-from-abstract-code',
  get_abac_roles_for_syllabus_flow:
    '/user-abac-role/api/get-roles-syllabus-flow',
  abac_role_search: '/abac-role/search',
  abac_role_action_search: '/abac-role-action/index/search',
  abac_role_action_delete: '/abac-role-action/index/delete',
  abac_role_module_search: '/abac-role-module/index/search',
  abac_role_module_delete: '/abac-role-module/index/delete',
  abac_role_new_from_abstract: '/abac-role/new-from-abstract',
  delete_abac_roles_of_target_item:
    '/abac-role/delete-abac-roles-of-target-item',
  participating_rate_of_department:
    '/api/v2/report/participating-rate-of-department',
  syllabus_rate_of_department: '/api/v2/report/syllabus-rate-of-department',
  report_credit_transfert: '/api/v2/report/credit-transfert',
  student_by_syllabus_search: '/api/v2/report/get-student-by-syllabus',

  get_progress_with_learning_items_of_learner:
    '/progress/api/get-progress-with-learning-items',
  get_target_discounts: '/finance-template/api/get-target-discounts',
  get_applicable_benefits_to_deposit_for_user:
    '/applied-fee-template/api/get-applicable-benefits-to-deposit-for-user',
  calculate_total_amount_payment_to_deposit_for_user:
    '/invoice/api/calculate-total-amount-payment-to-deposit-for-user',
  get_detail_information_forms_of_traing:
    '/degree/api/get-detail-information-forms-of-traing',
  /** ** report ***** */
  invited_groups: '/api/v2/report/get-list-of-invited-groups',
  get_fluctuating_groups: '/api/v2/report/get-fluctuating-groups',
  learning_materials: '/api/v2/report/get-learning-materials',
  classgroup_students_search: '/path/api/classgroup-students-search',
  students_for_request_study: '/api/v2/report/get-student-request-study',
  export_students_request_study: '/report/data/export-students-request-study',
  export_transcript: '/api/v2/report/export-transcript',
  export_students_by_course_ums: '/report/api/export-students-by-course-ums',
  export_students_by_course_ums_learning:
    '/report/api/export-students-by-course-ums-learning',
  export_summary_score_by_course: '/report/api/export-summary-score-by-course',
  user_abac_roles_delete: 'user-abac-role/delete',
  can_user_invite_in_all_organizations:
    'invite/api/can-user-invite-in-all-organizations',
  execute_enrolment_session: (id) =>
    `invite/api/execute-enrolment-session?id=${id}`,
  has_permissions: '/user-abac-role/has-permissions',
  has_perms: '/user-abac-role/has-perms',
  get_equivalent_position_options:
    '/equivalent-position/api/get-equivalent-position-options',
  get_equivalent_positions_for_input_auto_complete:
    '/equivalent-position/api/get-equivalent-positions-for-input-auto-complete',
  get_evn_equivalent_position_options:
    '/evn-equivalent-position/api/get-evn-equivalent-positions-options',
  evn_equivalent_positions: '/evn-equivalent-position/search',
  evn_equivalent_position_update: '/evn-equivalent-position/update',
  evn_equivalent_position_new: '/evn-equivalent-position/new',
  evn_equivalent_position_attach_job_position:
    '/evn-equivalent-position/attach-job-position',
  evn_equivalent_position_delete: '/evn-equivalent-position/delete',
  evn_equivalent_position_detach: '/evn-equivalent-position/detach',
  get_training_modes: '/path/api/get-training-modes',
  get_training_levels: '/path/api/get-training-levels',
  get_degrees: '/degree/get-degree-options-for-select-box',
  get_program_tree_by_plan: '/plan/api/get-program-tree-by-plan',
  update_major_program: '/plan/api/update-major-program',
  update_program_with_apply_prerequisites: '/path/api/apply-prerequisites',
  get_all_score_scale: '/skill/api/get-all-score-scale',
  get_credit_syllabuses_to_prerequisites_create_in_program:
    '/path/api/get-credit-syllabuses-to-prerequisites',
  get_credit_syllabuses_created_prerequisites_with_score_scale_in_program:
    '/syllabus/api/get-credit-syllabuses-created-prerequisites-with-score-scale',
  check_allow_to_edit_of_teaching_plan_major_program:
    '/plan/api/check-allow-to-edit-of-teaching-plan-major-program',
  training_plan_search: '/training-plan/search',
  get_training_plan_options: '/training-plan/api/get-training-plan-options',
  training_plan_stationery_usage_rate:
    '/asset/api/search-stationery-usage-rate?type=training-plan',
  enrolment_plan_search: '/enrolment-plan/search',
  get_enrolment_plan_options: '/enrolment-plan/api/get-enrolment-plan-options',
  enrolment_plan_members_search: '/enrolment-plan/api/members-search',
  get_enrolment_plan_credit_syllabus_options:
    '/enrolment-plan/api/get-enrolment-plan-credit-syllabus-options',
  get_enrolment_plan_course_options:
    '/enrolment-plan/api/get-enrolment-plan-course-options',
  get_suggested_enrolment_plan_code: '/enrolment-plan/api/get-suggested-code',
  is_enrolment_plan_ready_for_execute:
    '/enrolment-plan/api/is-enrolment-plan-ready-for-execute',
  get_not_approved_enrolment_plan_courses:
    '/enrolment-plan/api/get-not-approved-enrolment-plan-courses',
  get_enrolment_plan_members_who_are_not_assigned_to_all_enrolment_plan_credit_syllabuses:
    '/enrolment-plan/api/get-enrolment-plan-members-who-are-not-assigned-to-all-enrolment-plan-credit-syllabuses',
  get_enrolment_plan_members_who_need_confirmed:
    '/enrolment-plan/api/get-enrolment-plan-members-who-need-confirmed',
  get_enrolment_plan_statistics:
    '/enrolment-plan/api/get-enrolment-plan-statistics',
  get_credit_syllabuses_of_enrolment_plans:
    '/enrolment-plan/api/get-credit-syllabuses-of-enrolment-plans',
  add_user_to_enrolment_plan_group_through_request:
    '/enrolment-plan/api/add-user-to-enrolment-plan-group-through-request',
  add_group_users_to_enrolment_plan:
    '/enrolment-plan/api/add-group-users-to-enrolment-plan',
  submit_enrolment_plan_members_for_approval:
    '/enrolment-plan/api/submit-enrolment-plan-members-for-approval',
  set_score_final_for_subject: '/take/api/set-score-final-for-subject',
  survey_search: '/survey/search',
  default_message_template_search: (action, method, language) =>
    `/message-template/api/get-default-message-template?tpl_action=${action}&method=${method}&language=${language}`,
  message_template_params_search: (action) =>
    `/message-template/api/get-message-template-params?tpl_action=${action}`,
  message_template_search: '/message-template/search',
  school_message_templates_search: '/school-message-template/api/search',
  school_message_templates_update: (action, method, language) =>
    `/school-message-template/api/update?tpl_action=${action}&method=${method}&language=${language}`,
  process_scorm: '/site/api/process-scorm-file',
  add_survey_applied_items: '/survey/api/add-applied-items',
  remove_survey_applied_item: '/survey/api/remove-applied-item',
  survey_applied_items_search: '/survey/api/search-applied-items',
  survey_report_search: '/survey/api/search-all-survey-report',
  global_survey_report_search: '/survey/api/search-global-survey-report',
  budgetary_report_search:
    '/budgetary-allocations/api/search-budgetary-by-syllabus',
  update_survey_applied_item: '/survey/api/update-applied-item',
  remove_survey_takes: '/survey/take/remove-survey-take',
  save_survey_take: '/survey/take/save-survey-take',
  save_survey_take_question_answer:
    '/survey/take/save-survey-take-question-answer',
  save_survey_take_comment: '/survey/take/save-survey-take-comment',
  does_survey_take_exist: '/survey/take/does-survey-take-exist',
  export_student_make_final_contest:
    '/report/api/export-student-make-final-contest',
  export_student_confirm_final_contest:
    '/report/api/export-student-confirm-final-contest',
  manage_teacher_roles:
    '/user-abac-role/api/get-roles-of-user-follow-applied-scope',
  attach_smart_group_to_enrolment_plan:
    '/enrolment-plan/api/attach-smart-group-to-enrolment-plan',
  api_budgetary_search: 'budgetary-allocations/api/custom-search',
  api_budgetary_search_mode_equivalent:
    'budgetary-allocations/api/search-equivalent-positions',
  get_data_to_edit_equivalent_module:
    '/path/api/get-data-to-edit-equivalent-module',
  sync_users_between_smart_groups_and_enrolment_plans:
    '/enrolment-plan/api/sync-users-between-smart-groups-and-enrolment-plans',
  auto_insert_enrolment_plan_members_to_enrolment_plan_courses_if_possible:
    '/enrolment-plan/api/auto-insert-enrolment-plan-members-to-enrolment-plan-courses-if-possible',
  execute_enrolment_plans_if_possible:
    '/enrolment-plan/api/execute-all-possible-enrolment-plans',
  download_budget_template: 'import/data/get-template-form-import-budget',
  get_students_take_resit_exam: '/course/api/get-students-take-resit-exam',
  get_students_take_final_exam:
    '/course/api/search-user-who-can-take-final-exam',
  export_student_attendance_template:
    '/report/api/export-student-attendance-template',
  upload_and_save_local_file_info:
    '/file/index/upload-and-save-local-file-info',
  report_learning_course: '/report/api/report-learning-course',
  request_type_search: '/request-type/index/search',
  get_detail_request_type: '/request-type/api/get-detail',
  initiate_roles: '/abac-role/data/initiate-roles',
  generate_motp: '/school/api/generate-motp',
  export_learning_course: '/report/data/export-learning-course',
  export_course: '/course/data/export',
  search_enrolment_plan_members: '/api/v2/enrolment-plan/search-members',
  report_training_plan_members_group_by_organization:
    '/api/v2/training-plan/report-members-group-by-organization',
  training_plan_notification_for_approve_members_by_organization:
    'api/v2/training-plan/notify-user-to-approve-members-by-organization',
  export_stationery_future_projection:
    '/asset/api/export-stationery-future-projection',
  export_enrolment_plan_not_started_learners:
    'api/v2/report/export-enrolment-plan-not-started-learners',
  export_course_pmd_result_of_student:
    'report/api/export-course-pmd-result-of-student',
  enrolment_plan_credits_overall_progress:
    '/api/v2/report/get-enrolment-plan-credits-overall-progress',
  export_enrolment_plan_credits_overall_progress:
    'api/v2/report/export-enrolment-plan-credits-overall-progress',
  export_enrolment_plan_learners_progress:
    'api/v2/report/export-export-enrolment-plan-learners-progress',
  notification_warning_users_in_epl: '/enrolment-plan/api/send-notify-warning',
  add_enrolment_plan_member_relations:
    '/enrolment-plan/api/add-member-relations',
  get_all_enrolment_plan_teachers:
    '/enrolment-plan/api/get-all-enrolment-plan-teachers',
  get_surveys_targeting_enrolment_plan_teachers:
    '/enrolment-plan/api/get-surveys-targeting-enrolment-plan-teachers',
  search_survey_takes_targeting_enrolment_plan_teachers:
    '/enrolment-plan/api/search-survey-takes-targeting-enrolment-plan-teachers',
  enrolment_plan_teacher_survey_report:
    '/enrolment-plan/api/get-teacher-survey-report',
  enrolment_plan_credit_syllabus_survey_report:
    '/enrolment-plan/api/get-credit-syllabus-survey-report',
  new_enrolment_plan_teacher_survey_take:
    '/enrolment-plan/api/new-enrolment-plan-teacher-survey-take',
  save_survey_take_question_answer_for_surveys_of_enrolment_plan_users:
    '/enrolment-plan/api/save-survey-take-question-answer-for-surveys-of-enrolment-plan-users',
  get_surveys_targeting_enrolment_plan_credit_syllabuses:
    '/enrolment-plan/api/get-surveys-targeting-enrolment-plan-credit-syllabuses',
  search_survey_takes_targeting_enrolment_plan_credit_syllabuses:
    '/enrolment-plan/api/search-survey-takes-targeting-enrolment-plan-credit-syllabuses',
  new_enrolment_plan_credit_syllabus_survey_take:
    '/enrolment-plan/api/new-enrolment-plan-credit-syllabus-survey-take',
  save_survey_take_question_answer_for_surveys_of_a_target_in_enrolment_plan:
    '/enrolment-plan/api/save-survey-take-question-answer-for-surveys-of-a-target-in-enrolment-plan',
  get_enrolment_plan_surveys_batch_insert_options:
    '/enrolment-plan/api/get-enrolment-plan-surveys-batch-insert-options',
  student_have_not_passed_subject:
    '/api/v2/report/get-student-have-not-passed-subjects',
  get_number_of_learners_by_time:
    '/api/v2/report/get-number-of-learners-by-time',
  auto_create_enrolment_plan_courses_and_add_members:
    '/api/v2/enrolment-plan/auto-create-enrolment-plan-courses-and-add-members',
  can_auto_create_courses_and_add_users_to_enrolment_plan:
    '/api/v2/enrolment-plan/can-auto-create-courses-and-add-users-to-those-courses',
  search_user_who_can_approve_enrolment_plan_members:
    '/api/v2/enrolment-plan/search-user-who-can-approve-enrolment-plan-members',
  get_enrolment_plan_info: '/api/v2/enrolment-plan/get-info',
  is_any_training_plan_member_not_assigned_to_all_enrolment_plan_credit_syllabuses:
    '/api/v2/training-plan/is-any-training-plan-member-not-assigned-to-all-enrolment-plan-credit-syllabuses',
  is_any_enrolment_plan_member_not_assigned_to_all_enrolment_plan_credit_syllabuses:
    '/api/v2/enrolment-plan/is-any-enrolment-plan-member-not-assigned-to-all-enrolment-plan-credit-syllabuses',
  get_enrolment_plans_that_has_members_who_are_not_assigned_to_all_enrolment_plan_credit_syllabuses:
    '/api/v2/training-plan/get-enrolment-plans-that-has-members-who-are-not-assigned-to-all-enrolment-plan-credit-syllabuses',
  teacher_widgets: 'api/v2/site/teacher-widgets',
  export_equivalent_positions_programs:
    '/report/data/export-equivalent-positions-programs',
  are_all_enrolment_plans_of_training_plan_executed:
    '/training-plan/api/are-all-enrolment-plans-of-training-plan-executed',
  log_numbers_of_sessions_to_report_progress_master:
    '/report/api/log-numbers-of-sessions-to-report-progress-master',
  log_data_to_report_ums_master: '/report/api/log-data-to-report-ums-master',
  get_report_by_organization: '/api/v2/report/get-report-by-organization',
  get_report_by_operating_capacity:
    '/api/v2/report/get-report-by-operating-capacity',
  get_progress_master_by_academic_categorie:
    '/api/v2/report/get-progress-master-by-academic-categorie',
  get_report_teachers_of_organization:
    '/api/v2/report/get-report-teachers-of-organization',
  get_report_teaching_hours_of_teachers:
    '/api/v2/report/get-report-teaching-hours-of-teachers',
  export_report_teaching_hours_of_teachers:
    '/report/api/export-report-teaching-hours-of-teachers',
  get_report_average_survey_result:
    '/api/v2/report/get-report-average-survey-result',
  get_report_learning_material_creation:
    '/api/v2/report/get-report-learning-material-creation',
  get_progress_master_by_skill_level:
    '/api/v2/report/get-progress-master-by-skill-level',
  time_sheet_search: '/time-sheet/index/search',
  group_attendance_search: '/attendance/search',
  new_survey_take_for_feedback_users_in_group:
    '/group/survey/new-survey-take-for-feedback-users-in-group',
  search_survey_take_for_feedback_users_in_group:
    '/group/survey/search-survey-take-for-feedback-users-in-group',
  get_surveys_to_feedback_group_users:
    '/group/survey/get-surveys-to-feedback-group-users',
  search_survey_takes_batch_inserts_to_feedback_users_of_group:
    '/group/survey/search-survey-takes-batch-inserts-to-feedback-users-of-group',
  get_surveys_target_users: '/survey/api/get-surveys-target-users',
  get_report_survey_targeting_user:
    '/api/v2/report/get-report-survey-targeting-user',
  report_attendance_by_user: '/attendance/api/report-by-user',
  get_survey_take_batch_insertion_info:
    '/survey/batch-take/get-survey-take-batch-insertion-info',
  search_courses_that_user_has_role:
    '/course/api/search-courses-that-user-has-role',
  search_groups_that_user_is_gvcn: '/k12/group/search-groups-that-user-is-gvcn',
  delete_survey_takes_in_batch:
    '/survey/batch-take/delete-survey-takes-in-batch',
  get_bus_by_user: '/bus/api/get-bus-by-user',
  update_survey_take_result: '/survey/take/update-survey-take-result',
  finish_feedback_for_survey_takes:
    '/survey/batch-take/finish-feedback-for-survey-takes',
  get_report_by_scholarship: '/report/api/get-report-by-scholarship',
  get_student_status_report: '/report/api/get-student-status-report',
  get_student_status_export: '/report/api/get-student-status-export',
  get_list_of_student_failed_subject:
    '/report/api/get-list-of-student-failed-subject',
  get_gpa_of_student_by_semester:
    '/user-major/api/get-gpa-of-student-by-semester',
  export_gpa_of_student_by_semester:
    '/user-major/api/export-gpa-of-student-by-semester',
  get_amount_collected_according_to_the_cashier:
    '/invoice/api/get-amount-collected-according-to-the-cashier',
  export_amount_collected_according_to_the_cashier:
    '/invoice/api/export-amount-collected-according-to-the-cashier',
  get_debit_fees_of_student: '/fee/api/get-debit-fees-of-student',
  export_debit_fees_of_student: '/fee/api/export-debit-fees-of-student',
  get_list_of_student_failed_subject_export:
    '/report/api/get-list-of-student-failed-subject-export',
  get_open_ended_question_comments:
    '/take/api/get-open-ended-question-comments',
  save_open_ended_question_comment:
    '/take/api/save-open-ended-question-comment',
  get_open_ended_question_answer_histories:
    '/take/api/get-open-ended-question-answer-histories',
  get_attendance_report: '/report/api/get-attendance-report',
  get_attendance_export: '/report/api/get-attendance-export',
  get_survey_list_to_take: '/survey/api/get-survey-list-to-take',
  cache_organization_data_for_searching:
    'organization/data/cache-organization-data-for-searching',
  the_difficulty_of_questions_in_exam_template:
    '/exam-template/the-difficulty-of-questions',
  question_number_by_type: '/exam-template/get-question-number-by-type',
  get_exam_rounds_for_select_box:
    '/exam-round/schema-form/get-exam-rounds-for-select-box',
  get_exam_shifts_for_select_box:
    '/exam-shift/schema-form/get-exam-shifts-for-select-box',
  get_schools_for_select_box: '/school/schema-form/get-schools-for-select-box',
  reset_organizations_pid_from_department_parent_id:
    '/organization/data/reset-organizations-pid-from-department-parent-id',
  invoice_detail: '/invoice-detail/api/get-invoice-detail',
  // thêm vào db collection "invoice_release" khi xuất hóa đơn thành công
  add_data_invoice_release: 'invoice-release/new',
  // hilo.com.vn
  release_invoice_api:
    'https://cors-anywhere.herokuapp.com/https://apidemo.hilo.com.vn/api/hoadon/xuathoadon',
  get_pdf_invoice_by_key: (fkey, pattern) =>
    `https://cors-anywhere.herokuapp.com/https://apidemo.hilo.com.vn/api/business/invoicebykey?fkey=${fkey}&pattern=${pattern}`,
  get_courses_have_same_credit_syllabus:
    'course/api/get-courses-have-same-credit-syllabus',
  change_class_for_students: 'course/api/change-class-for-students',
  re_mark_course: 'course/api/re-mark-course',
  import_students_is_dropout: '/user/data/import-students-is-dropout',
  switch_student_status_to_dropout:
    '/user-major/api/switch-student-status-to-dropout',
  specialized_class_code_search: 'specialized-class-code/search',
  export_students_information_by_course:
    '/report/api/export-students-information-by-course',
  export_summary_transcripts_finishing_senior_group:
    '/group/api/export-summary-transcripts-finishing-senior-group',
  export_all_credits_of_program:
    '/api/v2/syllabus/export-all-credits-of-program',
  get_major_of_user: '/user-major/api/get-major-of-user',
};
