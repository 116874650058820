/**
 * Created by vohung on 22/05/2017.
 */
import { call, put, takeEvery } from 'redux-saga/effects';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import actions from 'actions/creators';
import Perm from 'common/utils/Perm';
import actionsError from 'actions/node/creators';

function* trackerProgress(data) {
  try {
    if (Perm.isGuest()) {
      return;
    }
    const {
      action,
      params,
      updateToStoreAfterSuccess,
      executeOnSuccess,
    } = data;
    const url = apiUrls.tracker_progress(action);
    const post = yield call(Requester.post, url, params, null, false);

    if (post && post.success && post.result) {
      if (typeof executeOnSuccess === 'function') {
        executeOnSuccess(post.result);
      }
      if (updateToStoreAfterSuccess) {
        yield put(actions.saveProgressMulti(post.result));
      }
    } else if (!post || !post.success) {
      yield put(actionsError.snackbar('error', post.message));
      //console.log('Tracker failed');
    }
  } catch (e) {
    //console.log(e);
  }
}

export default function* trackerProgressSaga() {
  yield takeEvery('TRACKER_PROGRESS_MULTI_REQUEST', trackerProgress);
}
