import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../../Naming';

const RejectedCourses = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RejectedCourses" */ 'components/front-end/dashboard/show-by-tab/courses/rejected/index'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: RejectedCourses,
};
