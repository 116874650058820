import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import get from 'lodash.get';
import {
  getSaveSurveyTakeParamsSelector,
  modes,
  steps,
} from 'common/learn/exercise';
import {
  getLearnCourseIidSelector,
  getLearnItemInfoSelector,
} from 'common/learn';
import {
  INIT_SURVEY,
  START_SURVEY,
} from 'actions/learn/exercise/normal/saga-creators';
import { saveItemInfo } from 'actions/learn';
import { saveSurveyTakeRequest } from 'actions/learn/saga-creators';
import apiUrls from 'api-endpoints';
import Requester from 'common/network/http/Request';
import coreFlow from './coreFlow';

function* saveProgressAndTake(itemIid) {
  const getSaveTakeParams = yield select(getSaveSurveyTakeParamsSelector);
  const saveTakeParams = yield call(getSaveTakeParams, itemIid);
  yield put(saveSurveyTakeRequest(itemIid, saveTakeParams));
}

function* waitForStartAction(itemIid) {
  yield take(
    (action) =>
      action.type === START_SURVEY &&
      action.itemIid &&
      String(action.itemIid) === String(itemIid),
  );
}

function* checkIfSurveyTakeExists(itemIid) {
  const selectInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(selectInfo, itemIid);
  const courseIid = yield select(getLearnCourseIidSelector);
  const data = yield call(Requester.get, apiUrls.does_survey_take_exist, {
    survey_applied_item_relation_id: info.survey_applied_item_relation_id,
    survey_iid: itemIid,
    item_iid: get(info, 'item_iid') || courseIid,
    type: get(info, 'item_type'),
  });

  if (data && data.success) {
    return data.result;
  }
  return false;
}

function* surveyFlow(itemIid) {
  const selectInfo = yield select(getLearnItemInfoSelector);
  const info = yield call(selectInfo, itemIid);

  if (info.mode === modes.NORMAL) {
    const doesSurveyTakeExists = yield call(checkIfSurveyTakeExists, itemIid);
    if (doesSurveyTakeExists) {
      yield put(saveItemInfo(itemIid, { step: steps.FINISHED }));
      return;
    }
  }

  if (!info.mode === modes.PREVIEW || !info.previewContentOnly) {
    yield put(saveItemInfo(itemIid, { step: steps.NOT_STARTED }));
    yield call(waitForStartAction, itemIid);
  }

  yield put(saveItemInfo(itemIid, { step: steps.MAIN }));

  yield call(coreFlow, itemIid);

  if (info.mode === modes.NORMAL) {
    yield call(saveProgressAndTake, itemIid);
  }
}

export default function* exerciseFlowSaga() {
  yield takeEvery(INIT_SURVEY, function*(action) {
    const { itemIid, info } = action;
    yield put(saveItemInfo(itemIid, info));
    yield call(surveyFlow, itemIid);
  });
}
