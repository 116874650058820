export const PAGE_TYPES = {
  assignment: 'assignment',
  home: 'home',
  attendanceManager: 'attendanceManager',
  attendance: 'attendance',
  courseRegistration: 'courseRegistration',
  blog: 'blog',
  currentCourses: 'currentCourses',
  event: 'event',
  examinations: 'examinations',
  FAQ: 'FAQ',
};

export const DEFINED_PAGE_CODES = {
  defaultPage: 'defaultPage',
  defaultDashboardPageLMS: 'defaultDashboardPageLMS',
  defaultDashboardPageUMS: 'defaultDashboardPageUMS',
  defaultCourseRegistration: 'defaultCourseRegistrationPage',
  defaultAssignmentPage: 'defaultAssignmentPage',
  defaultAttendanceManagerPage: 'defaultAttendanceManagerPage',
  defaultAttendancePage: 'defaultAttendancePage',
  defaultBlogPage: 'defaultBlogPage',
  defaultHomePageLMS: 'defaultHomePageLMS',
  PixelzHomePage: 'PixelzHomePage',
  VietEdHomePage: 'VietEdHomePage',
  LotusHomePage: 'LotusHomePage',
  EtecHomePage: 'EtecHomePage',
  XpeakHomePage: 'XpeakHomePage',
  UMSHomePage: 'UMSHomePage',
  EVNHomePage: 'EVNHomePage',
  defaultHomePageUMS: 'defaultHomePageUMS',
};

export const THEME = {
  default: 'default',
  ums: 'ums',
  sis: 'sis',
  vt: 'vt',
};

export const ThemeRegister = {
  [THEME.default]: {
    name: 'Almost customer will use this theme',
    code: THEME.default,
    seo: {
      desc: 'default',
      title: 'default theme',
      keyword: 'default',
    },
  },
};
export default THEME;
