const stylePrefix = 'STYLESHEET-AUTO-GENERATE-';

export const sheet = function(id) {
  if (id) {
    const oldStyle = document.getElementById(stylePrefix + id);
    if (oldStyle) {
      return oldStyle;
    }
  }

  // Create the <style> tag
  const style = document.createElement('style');

  // Add a media (and/or media query) here if you'd like!
  style.setAttribute('type', 'text/css');
  style.setAttribute('id', stylePrefix + id);

  // WebKit hack :(
  style.appendChild(document.createTextNode(''));

  // Add the <style> element to the page
  document.head.appendChild(style);

  return style;
};

export default sheet;
