function releaseStatus(state = false, action) {
  switch (action.type) {
    case 'PUSH_RELEASE_INVOICE_STATUS': {
      const { data } = action;
      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
}

export default releaseStatus;
