import { LayoutRegistered } from 'layouts/LayoutHelper';
import Loadable from 'components/common/async-loader/Loadable';
import RootLayout from 'layouts';
import FrontEnd from './frontend';
import FrontEndRoutes from './front-end-v2';
import {
  adminRootUrl,
  dashboardUrl,
  learnRootUrl,
  profileUrl,
  scheduleRoomUrl,
  systemRootUrl,
} from './root-url';

const RoomSchedule = Loadable({
  loader: () => import('components/timetable_v2/viewers/rooms'),
});

const Admin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "admin" */ 'routes/route-groups/admin'),
});

const System = Loadable({
  loader: () =>
    import(/* webpackChunkName: "system" */ 'routes/route-groups/system'),
});

const Dashboard = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard" */ 'routes/route-groups/dashboard'),
});

const Profile = Loadable({
  loader: () =>
    import(/* webpackChunkName: "profile" */ 'routes/route-groups/profile'),
});

const routes = [
  {
    component: RootLayout,
    routes: [
      {
        path: scheduleRoomUrl,
        component: RoomSchedule,
        layout: LayoutRegistered.embedded,
      },
      {
        path: dashboardUrl,
        component: Dashboard,
      },
      {
        path: profileUrl,
        component: Profile,
      },
      {
        path: adminRootUrl,
        component: Admin,
        layout: LayoutRegistered.admin,
      },
      {
        path: systemRootUrl,
        component: System,
        layout: LayoutRegistered.admin,
      },
      { ...FrontEndRoutes },
      ...FrontEnd(learnRootUrl),
    ],
  },
];

export default routes;
