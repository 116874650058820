const xml_xuat_hoa_don = (
  key,
  CusCode,
  Buyer,
  CusName,
  CusEmail,
  CusAddress,
  CusPhone,
  CusTaxCode,
  PaymentMethod,
  ArisingDate,
  ProdName,
  ProdUnit,
  ProdQuantity,
  ProdPrice,
  ProdAmount,
  Total,
  DiscountAmount,
  VATRate,
  VATAmount,
  Amount,
  AmountInWords,
) => {
  const data = `<Invoices>
        <Inv><key>${key}</key>
		    <Invoice>
                <CusCode>${CusCode}</CusCode>
                <Buyer>${Buyer}</Buyer>
                <CusName>${CusName}</CusName>
                <CusEmail>${CusEmail}</CusEmail>
                <CusAddress>${CusAddress}</CusAddress>
                <CusPhone>${CusPhone}</CusPhone>
                <CusTaxCode>${CusTaxCode}</CusTaxCode>
                <PaymentMethod>${PaymentMethod}</PaymentMethod>
                <ArisingDate>${ArisingDate}</ArisingDate>
                <Products>
                    <Product>
                        <Code>-2</Code>
                        <ProdName>${ProdName}</ProdName>
                        <ProdUnit>${ProdUnit}</ProdUnit>
                        <ProdQuantity>${ProdQuantity}</ProdQuantity>
                        <ProdPrice>${ProdPrice}</ProdPrice>
                        <Amount>${ProdAmount}</Amount>
                    </Product>
                </Products>
                <Total>${Total}</Total>
                <DiscountAmount>${DiscountAmount}</DiscountAmount>
                <VATRate>${VATRate}</VATRate>
                <VATAmount>${VATAmount}</VATAmount>
                <Amount>${Amount}</Amount>
                <AmountInWords>${AmountInWords}</AmountInWords>
		    </Invoice>
	    </Inv>
    </Invoices>`;

  return data;
};

export default xml_xuat_hoa_don;
