import { call, put, takeEvery } from 'redux-saga/effects';
import apiUrls from 'api-endpoints';
import Requester from 'common/network/http/Request';
import nodeActions from 'actions/node/creators';
import { LOGIN_OAUTH_SUCCESS_REQUEST } from 'actions/auth/saga-creators';
import { loginSuccess } from 'actions/auth';
import { closeLoginDialog } from 'actions/auth/auth-dialog';

function* loginOAuth(action) {
  const { loginType, token, params } = action;

  const data = yield call(
    Requester.post,
    apiUrls.login_oauth_success,
    Object.assign({}, params, {
      login: loginType,
      oauth_token: token,
    }),
  );

  if (data.success) {
    yield put(loginSuccess(data.result));
    yield put(closeLoginDialog());
  } else {
    yield put(nodeActions.snackbar(true, data.message));
  }
}

export default function* loginOAuthSaga() {
  yield takeEvery(LOGIN_OAUTH_SUCCESS_REQUEST, loginOAuth);
}
