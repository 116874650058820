/**
 * Created by hungvo on 19/04/2017.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import actions from 'actions/node/creators';
import CloseButton from './CloseButton';
import './stylesheet.scss';

class ModalDialog extends Component {
  iconButtonStyle = { position: 'absolute', right: 0, top: 0 };
  iconButtonIconStyle = { width: 30, height: 30 };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    const { openDialog, children, contentDialog } = this.props;
    if (typeof openDialog !== 'boolean' && (children || contentDialog)) {
      this.handleOpen();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { openDialog, optionsProperties, handleCloseDialog } = this.props;
    if (!openDialog && (nextProps.children || nextProps.contentDialog)) {
      this.handleOpen();
    } else if (
      optionsProperties &&
      optionsProperties.callbacks &&
      optionsProperties.callbacks.onCloseDialog &&
      openDialog &&
      !nextProps.openDialog
    ) {
      optionsProperties.callbacks.onCloseDialog();
    } else if (
      openDialog &&
      !nextProps.openDialog &&
      typeof handleCloseDialog === 'function'
    ) {
      handleCloseDialog();
    }
  }

  handleOpen = () => {
    const { dispatch, dialogKey } = this.props;
    dispatch(actions.handleOpenDialog({ openDialog: true }, dialogKey));
  };

  handleClose = () => {
    const { dispatch, dialogKey, handleCloseDialog } = this.props;

    if (handleCloseDialog) {
      return handleCloseDialog();
    }
    dispatch(actions.handleOpenDialog({ openDialog: false }, dialogKey));

    if (this.props.optionsProperties.clearHashOnClose) {
      // always remove the # part from dialog
      const url = window.location.pathname;
      window.history.pushState(null, null, url);
    }
  };

  render() {
    const { children, zIndex } = this.props;
    let { openDialog } = this.props;
    const contentDialog = children || this.props.contentDialog;
    let optionsProperties = this.props.optionsProperties || {};
    let contentStyle = optionsProperties.contentStyle || {};
    contentStyle = {
      zIndex: zIndex || 1500,
      marginTop: '1px',
      transform: 'none',
      ...contentStyle,
    };
    optionsProperties = { ...optionsProperties };
    delete optionsProperties.callbacks;

    let style = optionsProperties.style || {};
    style = {
      display: 'flex',
      paddingTop: 0,
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    };

    optionsProperties = { ...optionsProperties, contentStyle, style };

    if (!children && !contentDialog) {
      openDialog = false;
    }
    let dialogActions = [];
    if (
      optionsProperties &&
      optionsProperties.actions &&
      optionsProperties.actions.length
    ) {
      dialogActions = optionsProperties.actions;
    }

    if (!openDialog) {
      return null;
    }

    const showCloseButton = optionsProperties && optionsProperties.handleClose;

    return (
      <Dialog
        actions={dialogActions}
        open={!!openDialog}
        autoDetectWindowHeight
        contentClassName="dialog-content"
        repositionOnUpdate={false}
        onRequestClose={this.handleClose}
        className="modal_dialog no-header-dialog"
        {...optionsProperties}
        {...this.props}
      >
        {showCloseButton ? (
          <CloseButton
            closeButtonType={
              optionsProperties && optionsProperties.closeButtonType
            }
            onClick={this.handleClose}
          />
        ) : null}
        <div className="dialogContent">{contentDialog}</div>
      </Dialog>
    );
  }
}

ModalDialog.propTypes = {
  children: PropTypes.node,
  contentDialog: PropTypes.node,
  dialogKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dispatch: PropTypes.func,
  handleClose: PropTypes.bool,
  openDialog: PropTypes.bool,
};

ModalDialog.defaultProps = {
  actions: [],
  children: null,
  contentDialog: null,
  dialogKey: 'default',
  dispatch: () => {},
  handleClose: false,
  openDialog: null,
  optionsProperties: {},
};

export default connect()(ModalDialog);
