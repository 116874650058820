import HelperComponent from './pages/HelperComponent';
import homeRegisterConfig from './pages/home';
import attendanceConfig from './pages/attendence';
import attendanceManagerConfig from './pages/attendance-manager';
import assignments from './pages/assignments';
import notifications from './pages/notifications';
// import upcomingContest from './pages/upcoming-contest';
import courseRegistration from './pages/course-registration';
import progressReports from './pages/progress-reports';
import orgProgressReports from './pages/org-progress-reports';
import skillsConfigs from './pages/skills';
import faqConfigs from './pages/faq';
import currentCourses from './pages/current-courses';
import events from './pages/events';
import event from './pages/event';
import examinations from './pages/examinations';
import fee from './pages/fee';
import teacherSchedule from './pages/teacher-schedule';
import timetable from './pages/timetable';
import studentSchedule from './pages/student-schedule';
import transcript from './pages/transcript';
import userRequest from './pages/user-request';
import updateInfo from './pages/profile/update-info';
import changePassword from './pages/profile/change-password';
import setting from './pages/profile/setting';
import updateAvatar from './pages/profile/update-avatar';
import assignedCourses from './pages/lms/assigned-courses';
import news from './pages/news';
import completedCourses from './pages/lms/completed-courses';
import dashboardConfigs from './pages/dashboard';
import compulsoryCourses from './pages/lms/compulsory-courses';
import failedCourses from './pages/lms/failed-courses';
import feedback from './pages/lms/feedback';
import inProgressCourses from './pages/lms/in-progress-courses';
import myEnrolmentPlans from './pages/lms/my-enrolment-plans';
import overviewCourses from './pages/lms/overview-courses';
import overviewTimetable from './pages/lms/overview-timetable';
import publicCourses from './pages/lms/public-courses';
import rejectedCourses from './pages/lms/rejected-courses';
import reportAttendance from './pages/lms/report-attendance';
// import timetable from './pages/lms/timetable';
// import transcript from './pages/lms/transcript';
import userGoal from './pages/lms/user-goal';
import { DefinedUrlParams, getEventUrl } from '../routes/common';
import major from './pages/major';

export const rootUrl = '/site';

export default [
  {
    path: `${rootUrl}/attendance`,
    component: HelperComponent,
    configRegister: attendanceConfig,
  },
  {
    path: `${rootUrl}/dashboard`,
    component: HelperComponent,
    configRegister: dashboardConfigs,
  },
  {
    path: `${rootUrl}/teach/:${DefinedUrlParams.NTYPE}/:${
      DefinedUrlParams.COURSE_IID
    }-:${DefinedUrlParams.SESION_IID}/:${DefinedUrlParams.SUB_TYPE}`,
    component: HelperComponent,
    configRegister: attendanceManagerConfig,
  },
  {
    path: `${rootUrl}/user-goal`,
    component: HelperComponent,
    configRegister: userGoal,
  },
  {
    path: `${rootUrl}/teach/timetable`,
    component: HelperComponent,
    configRegister: timetable,
  },
  {
    path: `${rootUrl}/report-attendance`,
    component: HelperComponent,
    configRegister: reportAttendance,
  },
  {
    path: `${rootUrl}/rejected-courses`,
    component: HelperComponent,
    configRegister: rejectedCourses,
  },
  {
    path: `${rootUrl}/public-courses`,
    component: HelperComponent,
    configRegister: publicCourses,
  },
  {
    path: `${rootUrl}/overview-timetable`,
    component: HelperComponent,
    configRegister: overviewTimetable,
  },
  {
    path: `${rootUrl}/overview-courses`,
    component: HelperComponent,
    configRegister: overviewCourses,
  },
  {
    path: `${rootUrl}/my-enrolment-plans`,
    component: HelperComponent,
    configRegister: myEnrolmentPlans,
  },
  {
    path: `${rootUrl}/in-progress-courses`,
    component: HelperComponent,
    configRegister: inProgressCourses,
  },
  {
    path: `${rootUrl}/feedback`,
    component: HelperComponent,
    configRegister: feedback,
  },
  {
    path: `${rootUrl}/assigned-courses`,
    component: HelperComponent,
    configRegister: assignedCourses,
  },
  {
    path: `${rootUrl}/completed-courses`,
    component: HelperComponent,
    configRegister: completedCourses,
  },
  {
    path: `${rootUrl}/compulsory-courses`,
    component: HelperComponent,
    configRegister: compulsoryCourses,
  },
  {
    path: `${rootUrl}/failed-courses`,
    component: HelperComponent,
    configRegister: failedCourses,
  },
  {
    path: `${rootUrl}/assignments`,
    component: HelperComponent,
    configRegister: assignments,
  },
  {
    path: `${rootUrl}/notifications`,
    component: HelperComponent,
    configRegister: notifications,
  },
  // {
  //   path: `${rootUrl}/upcoming-contests`,
  //   component: HelperComponent,
  //   configRegister: upcomingContest
  // },
  {
    path: `${rootUrl}/register-courses`,
    component: HelperComponent,
    configRegister: courseRegistration,
  },
  {
    path: `${rootUrl}/progress-reports`,
    component: HelperComponent,
    configRegister: progressReports,
  },
  {
    path: `${rootUrl}/progress-reports-by-organization`,
    component: HelperComponent,
    configRegister: orgProgressReports,
  },
  {
    path: `${rootUrl}/Skills`,
    component: HelperComponent,
    configRegister: skillsConfigs,
  },
  {
    path: `${rootUrl}/faq`,
    component: HelperComponent,
    configRegister: faqConfigs,
  },
  {
    path: `${rootUrl}/current-courses`,
    component: HelperComponent,
    configRegister: currentCourses,
  },
  {
    path: getEventUrl('event', null, true),
    component: HelperComponent,
    configRegister: event,
  },
  {
    path: getEventUrl('events', null, true),
    component: HelperComponent,
    configRegister: events,
  },

  {
    path: `${rootUrl}/blog/:${DefinedUrlParams.SLUG}.html`,
    component: HelperComponent,
    configRegister: news,
  },
  {
    path: `${rootUrl}/blog/:${DefinedUrlParams.CATEGORY_SLUG}`,
    component: HelperComponent,
    configRegister: news,
  },
  {
    path: `${rootUrl}/blog/:${DefinedUrlParams.CATEGORY_SLUG}/:${
      DefinedUrlParams.SLUG
    }.html`,
    component: HelperComponent,
    configRegister: news,
  },
  {
    path: `${rootUrl}/blog`,
    component: HelperComponent,
    configRegister: news,
  },

  {
    path: `${rootUrl}/examinations`,
    component: HelperComponent,
    configRegister: examinations,
  },
  {
    path: `${rootUrl}/fee`,
    component: HelperComponent,
    configRegister: fee,
  },
  // {
  //   path: `${rootUrl}/teacher-schedule`,
  //   component: HelperComponent,
  //   configRegister: teacherSchedule
  // },
  {
    path: `${rootUrl}/schedule`,
    component: HelperComponent,
    configRegister: studentSchedule,
  },
  // {
  //   path: `${rootUrl}/student-schedule`,
  //   component: HelperComponent,
  //   configRegister: studentSchedule
  // },
  {
    path: `${rootUrl}/timetable`,
    component: HelperComponent,
    configRegister: timetable,
  },
  {
    path: `${rootUrl}/transcripts`,
    component: HelperComponent,
    configRegister: transcript,
  },

  {
    path: `${rootUrl}/update-info`,
    component: HelperComponent,
    configRegister: updateInfo,
  },
  {
    path: `${rootUrl}/update-avatar`,
    component: HelperComponent,
    configRegister: updateAvatar,
  },
  {
    path: `${rootUrl}/change-password`,
    component: HelperComponent,
    configRegister: changePassword,
  },
  {
    path: `${rootUrl}/setting`,
    component: HelperComponent,
    configRegister: setting,
  },
  {
    path: `${rootUrl}/request`,
    component: HelperComponent,
    configRegister: userRequest,
  },
  {
    path: `${rootUrl}/major`,
    component: HelperComponent,
    configRegister: major,
  },
  {
    path: rootUrl,
    component: HelperComponent,
    configRegister: homeRegisterConfig,
  },
];
