import { DefinedUrlParams, getCatalogueUrl } from 'routes/common';
import Loadable from 'components/common/async-loader/Loadable';
import {
  courseOverview,
  courseOverviewByPath,
  courseOverViewForUser,
  learnElement,
  learnElementWithSubChild,
  previewCourseLearn,
} from './learn';

const Tests = Loadable({
  loader: () => import('components/front-end/test'),
});
const TestComponent = Loadable({
  loader: () => import('components/test-component'),
});
const LearningNotAllowed = Loadable({
  loader: () => import('components/learn/not-allowed'),
});
const LearningOnePage = Loadable({
  loader: () => import('components/learn-one-page'),
});
const ForgotPassword = Loadable({
  loader: () => import('components/front-end/forgot-password/Layout'),
});
const UserUpdate = Loadable({
  loader: () => import('components/front-end/user/update/Layout'),
});
const TeacherLayout = Loadable({
  loader: () => import('components/front-end/teacher/Layout'),
});
const DashboardLayout = Loadable({
  loader: () => import('components/front-end/dashboard/Layout'),
});
const ProgressReportsLayout = Loadable({
  loader: () => import('components/front-end/reports/student-detail'),
});
const ProgressReportsByOrganization = Loadable({
  loader: () => import('components/front-end/reports/organization'),
});
const PaymentLayout = Loadable({
  loader: () => import('components/front-end/payment/Layout'),
});
const PathLayout = Loadable({
  loader: () => import('components/front-end/path'),
});
const SearchCoursesLayout = Loadable({
  loader: () => import('components/front-end/course/search'),
});
const HomePageLayout = Loadable({
  loader: () => import('components/front-end/homepage/Layout'),
});
const FAQ = Loadable({
  loader: () => import('components/front-end/faq'),
});
const TakeSurvet = Loadable({
  loader: () => import('components/front-end/dashboard/ums/survey/take'),
});
const Skills = Loadable({
  loader: () => import('components/front-end/skill'),
});
const Login = Loadable({
  loader: () => import('components/front-end/user/login'),
});
const Register = Loadable({
  loader: () => import('components/front-end/user/register'),
});
const RegisterSchool = Loadable({
  loader: () => import('components/admin/school/school/register'),
});
const InitializeSchool = Loadable({
  loader: () => import('components/admin/school/school/initialize'),
});
const TrainingPlanOrganizationDetail = Loadable({
  loader: () =>
    import('components/admin/training-plan/mainstage/members/members-by-organization/details'),
});

const Blog = Loadable({
  loader: () =>
    import(/* webpackChunkName: "blog" */ 'routes/route-groups/blog'),
});

export default (learnRootUrl) => {
  return [
    {
      componentId: 'Homepage',
      path: '/',
      component: HomePageLayout,
      exact: true,
    },
    {
      componentId: 'Reports',
      path: '/progress-reports',
      component: ProgressReportsLayout,
      exact: true,
    },
    {
      componentId: 'ProgressReportsByOrganization',
      path: '/progress-reports-by-organization',
      component: ProgressReportsByOrganization,
      exact: true,
    },
    {
      componentId: 'PathList',
      path: `${learnRootUrl}/course-list/:${DefinedUrlParams.PATH_IID}(\\d+)`,
      component: PathLayout,
      exact: true,
    },
    {
      componentId: 'SearchCourses',
      path: `${learnRootUrl}/course/search/:${DefinedUrlParams.SEARCH_KEY}`,
      component: SearchCoursesLayout,
      exact: true,
    },
    courseOverviewByPath,
    courseOverViewForUser,
    courseOverview,
    previewCourseLearn,
    learnElement,
    learnElementWithSubChild,
    {
      componentId: 'LearnNotAllowed',
      path: '/not-allowed-to-learn',
      component: LearningNotAllowed,
      exact: true,
    },
    {
      componentId: 'LearningSco',
      path: '/sco/:iid(\\d+)',
      component: LearningOnePage,
      exact: true,
    },
    {
      componentId: 'LearningScoEdit',
      path: '/sco/:edit(\\w+)/:iid(\\d+)',
      component: LearningOnePage,
      exact: true,
    },
    {
      componentId: 'Blog',
      path: '/blog',
      component: Blog,
    },
    {
      componentId: 'Teachers',
      path: '/teachers/',
      component: TeacherLayout,
      exact: true,
    },
    {
      componentId: 'ForgotPassword',
      path: '/forgot-password/',
      component: ForgotPassword,
      exact: true,
    },
    {
      componentId: 'UserUpdate',
      path: '/user/update',
      component: UserUpdate,
      exact: true,
    },
    {
      componentId: 'UserUpdateWithStep',
      path: `/user/update/:${DefinedUrlParams.STEP}`,
      component: UserUpdate,
      exact: true,
    },
    {
      componentId: 'Payment',
      path: '/pay',
      component: PaymentLayout,
      exact: true,
    },
    {
      componentId: 'TestComponent',
      path: `${learnRootUrl}/test`,
      component: TestComponent,
      exact: true,
    },

    {
      componentId: 'Test',
      path: '/tests',
      component: Tests,
      exact: true,
    },
    {
      componentId: 'TestWithType',
      path: '/tests/:type',
      component: Tests,
      exact: true,
    },
    {
      componentId: 'TestWithTypeAndIid',
      path: '/tests/:type/:iid',
      component: Tests,
    },
    {
      componentId: 'FAQ',
      path: '/faq',
      component: FAQ,
    },
    {
      componentId: 'TakeSurvey',
      path: '/survey/:surveyIid-:semesterIid',
      component: TakeSurvet,
    },
    {
      componentId: 'skills',
      path: '/skills',
      component: Skills,
    },
    {
      componentId: 'DashboardPage',
      path: '/:dashboard(learn|teach|dashboard|request)',
      component: DashboardLayout,
      exact: true,
    },
    {
      componentId: 'SubDashboardPage',
      path: `/:dashboard(learn|teach|dashboard|request)/:${
        DefinedUrlParams.NTYPE
      }`,
      component: DashboardLayout,
      exact: true,
    },
    {
      componentId: 'SubDashboardPage1',
      path: `/:dashboard(learn)/:${DefinedUrlParams.NTYPE}/:${
        DefinedUrlParams.TIMESTAMP
      }(\\d+)`,
      component: DashboardLayout,
      exact: true,
    },
    {
      componentId: 'DashboardPageNews',
      path: `/:dashboard(learn|teach)/:${DefinedUrlParams.NTYPE}/:${
        DefinedUrlParams.SLUG
      }.html`,
      component: DashboardLayout,
      exact: true,
    },
    {
      componentId: 'SubCategoriesDashboardPage',
      path: getCatalogueUrl('home-category'),
      component: HomePageLayout,
      exact: true,
    },
    {
      componentId: 'AttendanceSession',
      path: `/teach/:${DefinedUrlParams.NTYPE}/:${
        DefinedUrlParams.COURSE_IID
      }-:${DefinedUrlParams.SESION_IID}/:${DefinedUrlParams.SUB_TYPE}`,
      component: DashboardLayout,
      exact: true,
    },
    {
      componentId: 'SubDashboardPageTimeTable',
      path: `/learn/timetable/:${DefinedUrlParams.NTYPE}`,
      component: DashboardLayout,
      exact: true,
    },
    {
      componentId: 'login',
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      componentId: 'register',
      path: '/register',
      component: Register,
      exact: true,
    },
    {
      componentId: 'RegisterSchool',
      path: '/school/register',
      component: RegisterSchool,
      exact: true,
    },
    {
      componentId: 'InitializeSchool',
      path: '/initialize',
      component: InitializeSchool,
      exact: true,
    },
    {
      componentId: 'TrainingPlanOrganizationDetail',
      path:
        '/training-plan/:training_plan_iid/users-by-organization/:organization_iid',
      component: TrainingPlanOrganizationDetail,
    },
  ];
};
